import { StrategyType } from '../../../../types/public-flow-config.type';
import type { PublicConfigResponse } from '../../../../types/public-config.type';
import type { PublicFlowConfigResponse } from '../../../../types/public-flow-config.type';
import type { PublicPrimaryInsuredResponse, PublicSessionResponse } from '../../../../types/public-session.type';

export const getCalendlyUrl = (
  configData: PublicConfigResponse,
  publicFlowConfigData?: PublicFlowConfigResponse,
  publicSessionPrimaryInsuredData?: PublicPrimaryInsuredResponse,
  publicSessionData?: PublicSessionResponse
): string => {
  if (!publicSessionData) {
    return configData.config.integrations.calendly.primary_url;
  }
  const policyHolderUrl = publicSessionData.session?.person_with_high_risk_cancellation
    ? configData.config.integrations.calendly.policyholders_with_high_cancellation_risk_url
    : configData.config.integrations.calendly.policyholders_url;

  return publicSessionPrimaryInsuredData?.primary_insured.is_policyholder &&
    publicFlowConfigData?.flow_config.strategy === StrategyType.DigitalProfile
    ? policyHolderUrl
    : configData.config.integrations.calendly.primary_url;
};
