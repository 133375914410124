import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = css`
  margin-bottom: 20px;
`;

export const questionsWrapperCSS = (flex: boolean): SerializedStyles => css`
  ${flex &&
  css`
    display: flex;
    flex-direction: column;

    ${mq[0]} {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: end;
    }
  `}
`;

export const disclosureCSS = css`
  margin-top: 20px;
`;

export const headingCSS = css`
  font-weight: 700;
`;

export const disclosureHeadingCSS = css`
  margin-bottom: 15px;
`;

export const starCSS = css`
  color: ${theme.colors.errorRed};
`;
