export const QUOTE_CUSTOMIZATION_PREFIX = 'quote_customization';
const SCHEMA_KEYS = {
  billingPaymentType: 'billing/payment_type',
  billingPaymentPlan: 'billing/payment_plan',
  personMaritalStatus: 'person_marital_status',
  personWantsToSkipAutoCollection: 'auto_form/person_wants_to_skip_auto_collection',
  driversSectionKey: 'drivers_information',
  vehiclesSectionKey: 'vehicles_information',
  bundleContainerKey: 'core_bundle',
  autoPolicyEffectiveDay: 'quote_customization/auto_policy_effective_date',
  homePolicyEffectiveDay: 'quote_customization/home_policy_effective_date',
};

export const RELATIONSHIP_KIND_SPOUSE = 'spouse';
export const RELATIONSHIP_KIND_DOMESTIC_PARTNER = 'domestic_partner';
export const RELATIONSHIP_KIND_CHILD = 'child';
export const RELATIONSHIP_KIND_PARENT = 'parent';
export const RELATIONSHIP_KIND_RELATIVE = 'relative';
export const RELATIONSHIP_KIND_OTHER = 'other';
export const RELATIONSHIP_VARIANTS = [
  {
    label: 'Spouse',
    value: RELATIONSHIP_KIND_SPOUSE,
  },
  {
    label: 'Domestic Partner',
    value: RELATIONSHIP_KIND_DOMESTIC_PARTNER,
  },
  {
    label: 'Child',
    value: RELATIONSHIP_KIND_CHILD,
  },
  {
    label: 'Parent',
    value: RELATIONSHIP_KIND_PARENT,
  },
  {
    label: 'Relative',
    value: RELATIONSHIP_KIND_RELATIVE,
  },
  {
    label: 'Other',
    value: RELATIONSHIP_KIND_OTHER,
  },
];

export const GENDER_VARIANTS = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Non-binary',
    value: 'nonbinary',
  },
];

export const MARITAL_STATUS_VARIANTS = [
  {
    label: 'Single',
    value: 'single',
  },
  {
    label: 'Married',
    value: 'married',
  },
  {
    label: 'Divorced',
    value: 'divorced',
  },
];

export const PAGES = {
  driverDetails: 'drivers_details',
};

export const OTHER_OPTION_KEY = 'other';
export const OTHER_STRUCTURES_NONE_OPTION_KEY = 'none';

export default SCHEMA_KEYS;
