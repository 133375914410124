export interface PublicConfigResponse {
  config: {
    integrations: Integrations;
    websocket_url: string;
    environment: string;
  };
}

export enum EnvironmentType {
  Production = 'production',
  Staging = 'staging',
}

export interface Integrations {
  google: {
    api_key: string | null;
  };
  segment: {
    enabled: boolean;
    key: string | null;
  };
  sentry: {
    dsn: string | null;
    environment: string | null;
  };
  calendly: {
    primary_url: string;
    policyholders_url: string;
    policyholders_with_high_cancellation_risk_url: string;
  };
  recaptcha: {
    site_key_v3: string;
    site_key_v2: string;
  };
  bt: {
    public_api_key: string;
  };
  stripe: Record<string, string>;
  full_story: {
    org_id: string;
    org_slug: string;
  } | null;
}
