import { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelectedCarrier } from '../../../../../api/carrier';
import { useMutateAnswers, usePage } from '../../../../../api/page';
import { useSession } from '../../../../../api/session';
import useResponsive from '../../../../../hooks/use-responsive';
import DisclosureComponent from '../../../../../questionsForm/components/DisclosureComponent/DisclosureComponent';
import Question from '../../../../../questionsForm/components/Question/Question';
import { prepareCustomAnswers } from '../../../../../questionsForm/utils/answers/custom-schema-answers.util';
import { scrollToError } from '../../../../../questionsForm/utils/questions-form.util';
import Modal from '../../../../../shared/components/Modal/Modal';
import { FormElementTag } from '../../../../../types/form-element.type';
import { getDisclosuresSchema } from '../../../../../utils/schema.util';
import {
  containerCSS,
  disclosureCSS,
  disclosureHeadingCSS,
  headingCSS,
  questionsWrapperCSS,
  starCSS,
} from './ConfirmationQuoteModal.style';
import {
  getVisibleQuestionsQuestionCollection,
  prepareQuestionCollectionsToSubmit,
} from './ConfirmationQuoteModal.util';
import type { ConfirmationQuoteModalProps } from './ConfirmationQuoteModal.type';
import type { ModalActionButton } from '../../../../../shared/components/Modal/Modal.type';
import type { Answers } from '../../../../../types/answer.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { CustomSectionSchema } from '../../../../../types/section.type';
import type { FC } from 'react';

const ConfirmationQuoteModal: FC<ConfirmationQuoteModalProps> = ({ onSubmit, isOpen, toggleModal }) => {
  const { gid } = useParams() as DefaultLocation;

  const { data: carrierData } = useSelectedCarrier(gid);
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;

  const { data: pageData } = usePage<CustomSectionSchema>(gid, currentPageKey);
  const { mutateAsync: mutateAnswers } = useMutateAnswers(gid);

  const { isMobile } = useResponsive();

  const methods = useForm();
  const formAnswers = useWatch({ control: methods.control });
  const answers = (pageData && { ...prepareCustomAnswers(pageData), ...formAnswers }) ?? {};

  const [isLoading, setIsLoading] = useState(false);

  const sections = pageData?.page.sections ?? [];
  const disclosureSchema = getDisclosuresSchema(sections[0]);

  const visibleQuestionCollections = getVisibleQuestionsQuestionCollection(sections, answers);

  useEffect(() => {
    if (pageData?.answers) {
      methods.reset(prepareCustomAnswers(pageData), { keepDirty: true, keepTouched: true });
    }
  }, [pageData, methods]);

  const handleSubmit = async (data: Answers): Promise<void> => {
    const answersToSubmit = prepareQuestionCollectionsToSubmit({ ...data, ...answers }, visibleQuestionCollections);
    setIsLoading(true);
    await mutateAnswers(answersToSubmit);
    await onSubmit();
  };

  const handleOnCloseModal = (): void => {
    if (isLoading) {
      return;
    }
    toggleModal(false);
  };

  const actionButtons: ModalActionButton[] = [
    {
      text: 'Cancel',
      variant: 'secondary',
      onClick: handleOnCloseModal,
      disabled: isLoading,
    },
    {
      text: 'Submit Answers',
      type: 'submit',
      form: 'quote-form',
      onClick: () => null,
      isLoading,
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      isFullScreen={isMobile}
      title={`A few more questions required by ${carrierData?.carrier?.name}`}
      actionButtons={actionButtons}
      onClose={handleOnCloseModal}
    >
      <FormProvider {...methods}>
        <form id="quote-form" onSubmit={methods.handleSubmit(handleSubmit, scrollToError)}>
          {visibleQuestionCollections.map((collection) => (
            <div key={collection.key} css={containerCSS}>
              <div css={headingCSS}>{collection.heading}</div>
              <div css={questionsWrapperCSS(collection.questions.every((q) => !!q.grid_width))}>
                {collection.questions.map((q) => (
                  <Question
                    questionKey={q.key}
                    type={q.type}
                    label={q.label}
                    key={q.key}
                    gridWidth={q.grid_width}
                    validations={q.validations}
                    dense
                    variants={q.variants}
                    tags={[FormElementTag.SimpleCheckbox]}
                  />
                ))}
              </div>
            </div>
          ))}
          {disclosureSchema.length > 0 && (
            <div css={disclosureCSS}>
              <div css={[headingCSS, disclosureHeadingCSS]}>
                Disclosures <span css={starCSS}>*</span>
              </div>
              {disclosureSchema.map((d) => (
                <DisclosureComponent
                  disclosureGid={d.disclosure_gid}
                  key={d.key}
                  validations={d.validations}
                  content={d.content}
                  componentKey={d.key}
                  isLineHidden
                />
              ))}
            </div>
          )}
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ConfirmationQuoteModal;
