import { getVisibleComponents } from '../../../../../questionsForm/utils/questions-form.util';
import type { Answers, AnswerValue } from '../../../../../types/answer.type';
import type { FormComponentSchema } from '../../../../../types/form-component.type';
import type { CustomSectionSchema } from '../../../../../types/section.type';

export const prepareAnswersToSubmit = (
  answers: Answers,
  defaultAnswers?: Answers,
  sections?: CustomSectionSchema[]
): Record<string, AnswerValue> => {
  const visibleComponents = sections?.reduce<FormComponentSchema[]>((acc, section) => {
    return [...acc, ...getVisibleComponents(section.components, { ...defaultAnswers })];
  }, []);

  const resultObject: Answers = {};

  visibleComponents?.forEach((component) => {
    resultObject[component.key] = answers[component.key];
  });

  return resultObject;
};
