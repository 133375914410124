const parseStr = (str: string, key: string, options: Record<string, any>): string =>
  str.replace(new RegExp(`{${key}}`, 'g'), options[key] !== null ? options[key] : '');

const parseFromNested = (str: string, options: Record<string, any>): string =>
  Object.keys(options).reduce((deepStr: string, key: string) => parseStr(deepStr, key, options), str);

const stringTemplater = (tmpl = '', options: Record<string, any>): string =>
  tmpl &&
  Object.keys(options).reduce(
    (str, key) =>
      !!options[key] && typeof options[key] === 'object' && Object.keys(options[key]).length > 0
        ? parseFromNested(str, options[key])
        : parseStr(str, key, options),
    tmpl
  );

export default stringTemplater;
