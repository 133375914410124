import { css } from '@emotion/react';

export const updateIconCSS = css`
  margin-right: 8px;
`;

export const titleContainerCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
