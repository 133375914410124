import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text12CSS } from '../../../../../styles/typography.style';

export const containerCSS = css`
  margin-left: -16px;
  width: calc(100% + ${theme.pageMarginHorizontal.mobile * 2}px);

  ${mq[0]} {
    margin-left: 0;
    width: 100%;
  }
`;

export const disclaimerCSS = css`
  position: relative;
  ${text12CSS};
  color: ${theme.colors.gray60};
  padding: 65px 24px 0;
  text-align: center;

  &:before {
    position: absolute;
    content: '';
    top: 32px;
    left: -16px;
    right: -16px;
    height: 1px;
    display: block;
    width: calc(100% + ${theme.pageMarginHorizontal.mobile}px);
    background-color: ${theme.colors.gray10};
  }

  ${mq[0]} {
    padding: 24px 40px 0;

    &:before {
      display: none;
    }
  }
`;

export const wrapperCSS = css`
  margin: 0 auto;
  padding: inherit 0;

  ${mq[0]} {
    max-width: 890px;
  }
`;

export const cardCSS = css`
  box-shadow: none;
  border: none;
  border-top: 1px solid ${theme.colors.gray30};
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  ${mq[0]} {
    border: 1px solid ${theme.colors.gray30};
    box-shadow: ${theme.boxShadow.container};
    border-radius: ${theme.sizes.borderRadius}px;
  }
`;

export const loaderCSS = css`
  padding-top: 48px;
  width: 100%;
  ${mq[0]} {
    padding-top: 72px;
  }
`;

export const loaderImageCSS = css`
  width: 280px;
  height: 195px;

  ${mq[0]} {
    width: 335px;
    height: 250px;
  }
`;
