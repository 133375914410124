import { useMutation } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { Answers, AnswersResponse } from '../../types/answer.type';
import type { HTTPResponse } from '../../types/api.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

const useMutateQuote = (gid: string): UseBaseMutationResult<AnswersResponse, HTTPResponse, Answers> => {
  return useMutation({
    mutationFn: async (data: Answers) =>
      await api
        .patch<HTTPResponse<AnswersResponse>>(getApiUrl(API_PATH.quote, gid), {
          body: { data: { answers: data } },
        })
        .then((res) => res.data),
  });
};

export default useMutateQuote;
