import { css } from '@emotion/react';
import checkboxMark from '../../assets/check-mark.svg';
import { mq } from '../../styles/media-queries';
import theme from '../../styles/theme';
import { text12CSS, text14CSS } from '../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = (pageLoaded: boolean): SerializedStyles => css`
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  padding-top: 17px;

  ${mq[1]} {
    margin-left: 12px;
    margin-right: 12px;
    ${pageLoaded &&
    css`
      border: 1px solid ${theme.colors.azure95};
    `}

    border-radius: 88px;
    padding: 5px 10px 6px;
    flex-basis: auto;
  }
`;

export const itemCSS = (first: boolean, last: boolean, unavailable: boolean, current: boolean): SerializedStyles => css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    width: 50%;
    border-bottom: 1px solid ${unavailable ? theme.colors.gray10 : theme.colors.azure95};
    z-index: -1;
  }
  &:before {
    left: 0;
    ${first &&
    css`
      display: none;
    `}
  }
  &:after {
    right: 0;
    ${last &&
    css`
      display: none;
    `};
    ${current &&
    css`
      border-color: ${theme.colors.gray10};
    `}
  }
  ${mq[0]} {
    padding-left: 20px;
    padding-right: 20px;
    &:after,
    &:before {
      top: 6px;
    }
  }
  ${mq[1]} {
    padding-left: 26px;
    padding-right: 26px;
  }
`;

export const dotCSS = (completed: boolean, unavailable: boolean): SerializedStyles => css`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.azure62};
  background-color: ${theme.colors.azure62};
  ${unavailable &&
  css`
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.gray30};
  `};

  ${completed &&
  css`
    width: 5px;
    height: 5px;
    background-color: ${theme.colors.white};
    border-width: 3px;
  `}
  ${mq[0]} {
    width: 9px;
    height: 9px;
    border-width: 2px;
    ${completed &&
    css`
      background: ${theme.colors.azure62} url(${checkboxMark}) no-repeat center center;
      background-size: 7px;
    `}
  }
`;

export const labelCSS = (current: boolean): SerializedStyles => css`
  font-weight: ${current ? 700 : 500};
  ${text12CSS};
  line-height: 16px;
  margin-top: 2px;
  text-align: center;
  ${mq[0]} {
    ${text14CSS};
    line-height: 20px;
    margin-top: 0;
    white-space: nowrap;
  }
`;
