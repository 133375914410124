import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutateAnswers, usePage } from '../../api/page';
import { useMutateSession, useSession } from '../../api/session';
import { SEGMENT } from '../../constants/analytics';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import SCHEMA_KEYS from '../../constants/schema-hardcoded-keys';
import { useSegment } from '../../hooks/use-segment';
import Button from '../../shared/components/Button/Button';
import Modal from '../../shared/components/Modal/Modal';
import { ButtonDirection } from '../../shared/components/Modal/Modal.type';
import { getCurrentNavigationPage, getNextNavigationKeys } from '../../utils/current-form-keys.util';
import { getPathByPageType } from '../../utils/route-path.util';
import { containerCSS, loaderCSS, modalTextCSS, skipButtonCSS } from './SkipPageButton.style';
import type { SkipPageButtonProps } from './SkipPageButton.type';
import type { DefaultLocation } from '../../types/route-params.type';
import type { SessionResponse } from '../../types/session.type';
import type { FC } from 'react';

const SkipPageButton: FC<SkipPageButtonProps> = ({ customCSS, buttonVariant = 'secondary' }) => {
  const { gid, flow } = useParams() as DefaultLocation;

  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const { data: pageData } = usePage(gid, currentPageKey);

  const { mutateAsync: mutateSession, isPending: isMutatingSession } = useMutateSession(gid);
  const { mutateAsync: mutateAnswers, isPending: isMutatingAnswers } = useMutateAnswers(gid);

  const isPending = isMutatingSession || isMutatingAnswers;

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState(false);
  const { track } = useSegment();
  // @TODO make this logic isolated and reusable in next generations
  const checkNextPage = (updatedSessionData: SessionResponse): void => {
    const nextCurrentPage = getCurrentNavigationPage(updatedSessionData);
    if (nextCurrentPage) {
      navigate(getPathByPageType(nextCurrentPage?.page_type, gid, flow), { replace: true });
    }
  };

  const updateAnswers = async (): Promise<void> => {
    const { answers } = await mutateAnswers({ [SCHEMA_KEYS.personWantsToSkipAutoCollection]: 'yes' });
    queryClient.setQueryData([QUERY_CACHE_KEYS.page, gid, currentPageKey], {
      ...pageData,
      answers,
    });
  };

  const onSkip = async (): Promise<void> => {
    const { pageKey, sectionKey } = getNextNavigationKeys(sessionData as SessionResponse, pageData?.answers);
    await updateAnswers();

    const newSessionData = await mutateSession({
      current_page_key: pageKey,
      current_section_key: sectionKey,
      completed_page_key: currentPageKey,
    });

    track(SEGMENT.events.pageCompleted, {
      page: currentPageKey,
    });

    queryClient.setQueryData([QUERY_CACHE_KEYS.session, gid], newSessionData);

    checkNextPage(newSessionData);
  };

  const onClose = (): void => {
    if (isPending) {
      return;
    }

    setIsOpen(false);
  };

  return (
    <div css={[containerCSS, customCSS]}>
      <Button customCSS={skipButtonCSS} variant={buttonVariant} onClick={() => setIsOpen(true)}>
        Skip to Home Quote
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        buttonDirection={ButtonDirection.Column}
        actionButtons={[
          { text: 'Cancel', variant: 'secondary', onClick: onClose, disabled: isPending },
          {
            text: `Skip to Home Quote`,
            type: 'submit',
            isLoading: isPending,
            onClick: () => onSkip(),
          },
        ]}
        title={
          <>
            <div css={loaderCSS} />
            <span>Your home quote is being prepared</span>
          </>
        }
      >
        <div css={modalTextCSS}>
          Skipping auto questions means you&apos;ll get a home quote only. Choosing to answer both secures a bundled
          home and auto quote, unlocking potential savings of up to 20%. Answering auto questions takes less than 5
          minutes to complete.
        </div>
      </Modal>
    </div>
  );
};

export default SkipPageButton;
