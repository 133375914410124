import { FormElementTag } from '../../../../types/form-element.type';
import { isTrackForbidden } from '../../../utils/form-element-tags.util';
import QuestionWrapper from '../../QuestionWrapper/QuestionWrapper';
import DatePickerInput from '../DatePickerInput/DatePickerInput';
import DateInput from './DateInput';
import type { SpecificQuestionProps } from '../Question.type';
import type { FC } from 'react';

const DateInputQuestion: FC<SpecificQuestionProps> = ({
  questionKey,
  label,
  hideLabel,
  description,
  hint,
  icon,
  workflowHeading,
  isDisabled,
  placeholder = 'MM-DD-YYYY',
  mask = '99-99-9999',
  validations,
  gridWidth,
  dense,
  tags = [],
}) => {
  const isDatePicker = tags?.includes(FormElementTag.DatePicker);
  const DateComponent = isDatePicker ? DatePickerInput : DateInput;

  return (
    <QuestionWrapper
      questionKey={questionKey}
      label={label}
      hideLabel={hideLabel}
      hint={hint}
      description={description}
      icon={icon}
      workflowHeading={workflowHeading}
      isDisabled={isDisabled}
      gridWidth={gridWidth}
      inputProps={{
        name: questionKey,
        inputId: questionKey,
        isDisabled,
        placeholder,
        mask,
        validations,
        description,
        renderDropdownInBody: tags.includes(FormElementTag.RenderDropDownInBody),
        trackingForbidden: isTrackForbidden(tags),
        dense,
      }}
      inputComponent={DateComponent}
    />
  );
};

export default DateInputQuestion;
