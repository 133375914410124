import UpdateSmallIcon from '../../../../../assets/update-small.svg?react';
import UpdateIcon from '../../../../../assets/update.svg?react';
import Button from '../../../../../shared/components/Button/Button';
import {
  containerCSS,
  iconContainerCSS,
  textBlockCSS,
  unsavedChangesCSS,
  updateButtonCSS,
  updateIconCSS,
} from './RecalculateQuoteButton.style';
import type { RecalculateQuoteButtonProps } from './RecalculateQuoteButton.type';
import type { FC } from 'react';

const RecalculateQuoteButton: FC<RecalculateQuoteButtonProps> = ({ onUpdate }) => {
  return (
    <div css={containerCSS}>
      <div css={textBlockCSS}>
        <div css={iconContainerCSS}>
          <UpdateIcon css={updateIconCSS()} />
        </div>
        <div>
          <span css={unsavedChangesCSS}>You have unsaved changes.</span> When you&apos;re done customizing, click to
          update your quote.
        </div>
      </div>
      <Button variant="secondary" css={updateButtonCSS} onClick={onUpdate}>
        <UpdateSmallIcon css={updateIconCSS(true)} />
        Update Quote
      </Button>
    </div>
  );
};

export default RecalculateQuoteButton;
