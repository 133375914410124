import { Global } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFeatureFlags } from '../../../../api/feature-flags';
import { usePage } from '../../../../api/page';
import useEngagement from '../../../../api/people/use-engagement';
import { usePublicConfig } from '../../../../api/publicConfig';
import { useInitQuoteRequestSubscription } from '../../../../api/quote';
import { useSlowQuotesFinishedSubscription } from '../../../../api/quote/use-slow-quotes-finished-subscription';
import { useSession } from '../../../../api/session';
import { SEGMENT } from '../../../../constants/analytics';
import { FEATURE_FLAGS } from '../../../../constants/feature-flags';
import SCHEMA_KEYS from '../../../../constants/schema-hardcoded-keys';
import usePhoneNumber from '../../../../hooks/use-phone-number';
import { useSegment } from '../../../../hooks/use-segment';
import { convertAnswerToBoolean, questionHasAnswer } from '../../../../questionsForm/utils/answers/answers.util';
import useDynamicFlowConfigChange from '../../../../shared/hooks/use-dynamic-flow-config-change';
import { ANSWER_BOOLEAN_VALUE } from '../../../../types/answer.type';
import { HOME_POLICY_TYPES } from '../../../../types/quote.type';
import { EXPERIMENT_REPORT_VARIANTS, getFeatureFlagConfig } from '../../../../utils/feature-flags.util';
import CallAgentRibbon from './CallAgentRibbon/CallAgentRibbon';
import DigitalProfileBundleQuoteContent from './DigitalProfileBundleQuoteContent/DigitalProfileBundleQuoteContent';
import DigitalProfileInterstitial from './DigitalProfileInterstitial/DigitalProfileInterstitial';
import DigitalProfileNoQuotesContent from './DigitalProfileNoQuotesContent/DigitalProfileNoQuotesContent';
import DigitalProfileQuoteContent from './DigitalProfileQuoteContent/DigitalProfileQuoteContent';
import { containerCSS, hideFooterCSS, removeMainPaddingCSS } from './DigitalProfileQuotePage.style';
import { getNoQuoteMessageReason, isHomeQuotePresent } from './DigitalProfileQuotePage.util';
import type { ExperimentVariation } from '../../../../types/feature-flag.type';
import type { DigitalProfileQuote } from '../../../../types/quote.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC, ReactNode } from 'react';

const DigitalProfileQuotePage: FC = () => {
  const { gid } = useParams() as DefaultLocation;

  const { data: configData } = usePublicConfig();
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const engagementGid = sessionData?.session.engagement_gid;
  const { data: pageData } = usePage(gid, currentPageKey);
  const { phoneNumber } = usePhoneNumber();
  const { data: engagementData, refetch: refetchEngagement } = useEngagement(gid, engagementGid, false);

  const { data: featureFlags, isPending: isFeatureFlagsLoading } = useFeatureFlags(gid);
  const isLeadCreationEnabled = getFeatureFlagConfig(
    featureFlags?.feature_flags,
    FEATURE_FLAGS.leadCreationOnDP
  ).enabled;
  const emailSMSExp = getFeatureFlagConfig(featureFlags?.feature_flags, FEATURE_FLAGS.emailSmsDP);

  const { isSlowQuotesFinished } = useSlowQuotesFinishedSubscription(gid, configData?.config.websocket_url);
  const webSocketUrl = configData?.config.websocket_url;
  const { quoteRequest } = useInitQuoteRequestSubscription<DigitalProfileQuote>({
    gid,
    webSocketUrl,
  });

  const { track } = useSegment();

  const { isFinished: isFlowConfigUpdateFinished } = useDynamicFlowConfigChange<DigitalProfileQuote>({
    quoteRequest,
    currentPageKey,
  });

  const [isEngagementFetching, setIsEngagementFetching] = useState(false);

  const isAutoInformationSkipped =
    questionHasAnswer(pageData?.answers[SCHEMA_KEYS.personWantsToSkipAutoCollection]) &&
    pageData?.answers[SCHEMA_KEYS.personWantsToSkipAutoCollection] === ANSWER_BOOLEAN_VALUE.yes;

  const hasBundleAnswer = questionHasAnswer(pageData?.answers.person_has_core_bundle_interest);
  const allowBundle = hasBundleAnswer && !isAutoInformationSkipped;
  const bundleAnswer: boolean | null = allowBundle
    ? convertAnswerToBoolean(pageData?.answers.person_has_core_bundle_interest)
    : null;

  const isQuoteRequestFinished = !!quoteRequest?.finished_at;
  const firstQuotesGroup = isQuoteRequestFinished ? quoteRequest.groups[0] : null;

  const quotes = useMemo(() => firstQuotesGroup?.quotes ?? [], [firstQuotesGroup]);

  const sortedHomeQuotes = useMemo(
    () =>
      quotes
        ?.filter((q) => HOME_POLICY_TYPES.includes(q.policy_type))
        .sort((a, b) => {
          return bundleAnswer
            ? parseFloat(a.multi_policy_discount_premium ?? a.premium) -
                parseFloat(b.multi_policy_discount_premium ?? b.premium)
            : parseFloat(a.premium) - parseFloat(b.premium);
        }) ?? [],
    [quotes, bundleAnswer]
  );

  const noQuotesVariation = sortedHomeQuotes.length === 0 && isQuoteRequestFinished;

  const isEmailSMSAudience = emailSMSExp.enabled && noQuotesVariation && !!quoteRequest?.slow_quotes_in_progress;

  const isBundleQuotePage = bundleAnswer && isHomeQuotePresent(sortedHomeQuotes);

  const isLoading =
    !engagementGid || !pageData || !isFlowConfigUpdateFinished || !isQuoteRequestFinished || isEngagementFetching;

  const isSoftStopNotEnabled = isLeadCreationEnabled && !engagementData?.engagement?.soft_stop_page_enabled;
  const hideTopRibbon = noQuotesVariation && isSoftStopNotEnabled;

  useEffect(() => {
    if (isSlowQuotesFinished) {
      track(SEGMENT.events.shadowAgentRequestIsFinished);
    }
  }, [isSlowQuotesFinished, track]);

  useEffect(() => {
    const call = async (): Promise<void> => {
      setIsEngagementFetching(true);
      await refetchEngagement();
      setIsEngagementFetching(false);
    };

    if (isQuoteRequestFinished && noQuotesVariation && isLeadCreationEnabled) {
      call();
    }
  }, [isQuoteRequestFinished, sortedHomeQuotes, isLeadCreationEnabled, refetchEngagement, noQuotesVariation]);

  useEffect(() => {
    if (isQuoteRequestFinished && isEmailSMSAudience) {
      track(SEGMENT.events.experimentViewed, {
        experiment_id: emailSMSExp.name,
        variation_id: EXPERIMENT_REPORT_VARIANTS[emailSMSExp.variant as ExperimentVariation],
        gid: sessionData?.session.engagement_gid,
      });
    }
  }, [isQuoteRequestFinished, emailSMSExp, track, isEmailSMSAudience, sessionData]);

  const onPhoneClicked = (): void => {
    track(SEGMENT.events.phoneConversationInitiated, {
      page: currentPageKey,
      location: SEGMENT.locations.page,
    });
  };

  const onScheduleCall = (): void => {
    track(SEGMENT.events.conversationSchedulingInitiated, {
      page: currentPageKey,
    });
  };

  const getQuoteContent = (allQuotes: DigitalProfileQuote[]): ReactNode => {
    if (!isQuoteRequestFinished) {
      return null;
    }

    switch (true) {
      case noQuotesVariation:
        return (
          <DigitalProfileNoQuotesContent
            bundleAnswer={bundleAnswer}
            noQuotesReason={getNoQuoteMessageReason(allQuotes, isSoftStopNotEnabled)}
            onPhoneClicked={onPhoneClicked}
            onScheduleCallClick={onScheduleCall}
          />
        );
      case isBundleQuotePage && !!firstQuotesGroup:
        return (
          <DigitalProfileBundleQuoteContent
            quotes={allQuotes}
            quotesGroup={firstQuotesGroup}
            onPhoneClicked={onPhoneClicked}
            onScheduleCallClick={onScheduleCall}
          />
        );
      default:
        return (
          <DigitalProfileQuoteContent
            quotes={sortedHomeQuotes}
            bundleAnswer={bundleAnswer}
            onPhoneClicked={onPhoneClicked}
            onScheduleCallClick={onScheduleCall}
          />
        );
    }
  };

  if (isFeatureFlagsLoading) {
    return <Global styles={[hideFooterCSS, removeMainPaddingCSS]} />;
  }

  if (isLoading) {
    return (
      <>
        <Global styles={[hideFooterCSS, removeMainPaddingCSS]} />
        <DigitalProfileInterstitial shouldShowBundleMessage={!!bundleAnswer} />
      </>
    );
  }

  return (
    <>
      {!hideTopRibbon && <CallAgentRibbon phoneNumber={phoneNumber} onPhoneClick={onPhoneClicked} />}
      <div css={containerCSS(!hideTopRibbon, noQuotesVariation)}>{getQuoteContent(quotes)}</div>
    </>
  );
};

export default DigitalProfileQuotePage;
