import { css } from '@emotion/react';
import theme from '../../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

const fontStylesCSS = css`
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  font-feature-settings:
    'pnum' on,
    'lnum' on;
`;

// calendar picker
export const dayCSS = css`
  background-color: ${theme.colors.white};
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin: 6px;
  ${fontStylesCSS};

  &:hover {
    background-color: ${theme.colors.gray10};
  }
  &.Mui-selected {
    background-color: ${theme.colors.azure50};
    color: ${theme.colors.white};
  }
  &.MuiPickersDay-today:not(.Mui-selected) {
    border-color: ${theme.colors.azure50};
    color: ${theme.colors.azure50};
  }
  .MuiTouchRipple-root {
    display: none;
  }
`;

export const layoutCSS = css`
  position: relative;
  top: 8px !important;
  width: 272px;
  border: 1px solid ${theme.colors.gray30};
  filter: drop-shadow(2px 4px 0px rgba(0, 0, 0, 0.12));
  border-radius: 4px;
  z-index: 9999;
  ${fontStylesCSS};

  .MuiPickersYear-root {
    flex-basis: 33%;
    .MuiPickersYear-yearButton {
      ${fontStylesCSS};
      border-radius: 4px;
      &.Mui-selected {
        background-color: ${theme.colors.azure50};
      }
      &:hover {
        background-color: ${theme.colors.gray10};
      }
    }
  }
  .MuiPickersArrowSwitcher-button {
    top: 50%;
    border-radius: 4px;
    margin: 0 12px;
    color: ${theme.colors.black};
    &:hover {
      background-color: ${theme.colors.gray10};
    }
    .MuiTouchRipple-root {
      display: none;
    }
    padding: 4px;
  }
  .MuiPickersCalendarHeader-labelContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    ${fontStylesCSS};
    padding: 4px;
    border-radius: 4px;
    text-transform: uppercase;
    white-space: nowrap;
    &:hover {
      background-color: ${theme.colors.gray10};
    }
  }
  .MuiPickersCalendarHeader-label {
    margin: 0;
  }
  .MuiYearCalendar-root {
    width: 100%;
    padding: 0;
  }
  .MuiPickersCalendarHeader-root {
    position: relative;
    padding: 12px;
    margin: 0;
    box-shadow: inset 0px -1px 0px ${theme.colors.gray10};
  }
  .MuiDateCalendar-root {
    max-height: 330px;
    width: 272px;
    background: ${theme.colors.white};
    border-radius: 4px;
  }
  .MuiDayCalendar-weekDayLabel {
    width: 24px;
    height: 24px;
    margin: 0 6px;
    color: ${theme.colors.black};
    ${fontStylesCSS};
  }
  .MuiDayCalendar-weekContainer {
    padding: 0 10px;
  }
  .MuiPickersDay-dayOutsideMonth {
    color: ${theme.colors.gray30};
  }
  .MuiDayCalendar-header {
    margin-top: 12px;
  }
`;

export const nextIconCSS = css`
  position: absolute;
  left: 0;
  transform: translateY(-50%) rotate(-90deg);
`;

export const previousIconCSS = css`
  position: absolute;
  right: 0;
  transform: translateY(-50%) rotate(90deg);
`;

export const switchIconCSS = css`
  transition: 3s;
  &:hover {
    background: none;
  }
  width: 16px;
  margin-left: 4px;
  padding: 0;
  .MuiTouchRipple-root {
    display: none;
  }
`;

export const calendarIconCSS = (hasError: boolean): SerializedStyles => css`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 24px;
  height: 24px;
  color: ${hasError ? theme.colors.errorRed : theme.colors.gray60};
`;

export const descriptionCSS = css`
  padding: 16px;
  font-size: 14px;
  background: ${theme.colors.azure95};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
